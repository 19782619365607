<template>
  <div>
    <v-row
      no-gutters
      class="mb-5"
    >
      <v-col cols="12">
        <v-toolbar flat>
          <!-- <v-app-bar-nav-icon>
            <v-menu
              offset-y
              :close-on-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>

                <v-list-item
                  v-for="(ac, acIndex) in actions"
                  :key="`wee-tble-action-${acIndex}`"
                  @click="onMenuClick(ac.value)"
                >
                  <v-list-item-icon>
                    <v-icon
                      :color="ac.color"
                      v-text="ac.icon"
                    ></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ ac.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar-nav-icon> -->
          <v-app-bar-nav-icon v-if="$route.meta.pageTitle.icon">
            <v-icon>{{ $route.meta.pageTitle.icon }}</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>{{ title }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            hide-details
            :placeholder="$t('base.search')"
            filled
            rounded
            dense
            prepend-inner-icon="mdi-magnify"
            v-model="searchText"
            @keyup="$emit('update-search', searchText)"
          ></v-text-field>

          <div
            v-for="(ac, acIndex) in actions"
            :key="`wee-tble-action-${acIndex}`"
          >
            <v-tooltip
              bottom
              :color="ac.color"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="onMenuClick(ac.value)"
                >
                  <v-icon
                    v-text="ac.icon"
                    :color="ac.color"
                  ></v-icon>
                </v-btn>
              </template>
              <span>{{ ac.text }}</span>
            </v-tooltip>
          </div>
          <!-- <v-btn
            icon
            v-for="(ac, acIndex) in actions"
            :key="`wee-tble-action-${acIndex}`"
            @click="onMenuClick(ac.value)"
          >
            <v-icon
              v-text="ac.icon"
              :color="ac.color"
            ></v-icon>
          </v-btn> -->
        </v-toolbar>
      </v-col>
    </v-row>

    <slot name="advancesearch">
      <form
        id="wee-tbl-seacherable-form"
        v-show="showAdvanceSearch"
      >
        <v-card class="mx-auto">
          <v-card-title>{{ $t("base.searchAdvance") }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(seachable, searchIndex) in searchableHeaders"
                :key="`wee-tbl-seacherable-${searchIndex}`"
                cols="12"
                sm="6"
                md="3"
              >
                <template v-if="seachable.status">
                  <v-combobox
                    item-text="text"
                    item-value="val"
                    :return-object="true"
                    :items="[{text:$t('base.all'),val:''},{text:$t('base.true'),val:'1'}, {text:$t('base.false'),val:'0'}]"
                    :label="$t(seachable.label)"
                    @change="manageAdvanceSearch($event && $event.val ? $event.val : $event, seachable.column)"
                  ></v-combobox>
                </template>
                <template v-else>
                  <v-text-field
                    :label="$t(seachable.label)"
                    :id="seachable.column"
                    :name="seachable.column"
                    @change="manageAdvanceSearch($event, seachable.column)"
                  ></v-text-field>
                </template>

              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="resetSearch"
            >
              {{ $t("base.clear") }}
            </v-btn>

            <v-btn
              text
              @click="$emit('on-advance-search', advanceSearchs)"
            >
              <v-icon>mdi-magnify</v-icon>{{ $t("base.submit") }}
            </v-btn>
            <v-btn
              text
              @click="showAdvanceSearch = !showAdvanceSearch"
            >
              <v-icon>mdi-close</v-icon>{{ $t("base.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </slot>

    <v-card
      class="mx-auto"
      outlined
    >
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <slot name="theader">
                <tr>
                  <th>
                    <v-checkbox v-model="selectedAll"></v-checkbox>
                  </th>
                  <th
                    v-for="(tblHeader, tblIndex) in headers"
                    :key="`tblHeader-${tblIndex}`"
                  >
                    <template v-if="tblHeader.sortable">
                      <div
                        style="cursor:pointer;"
                        color="red"
                        class="text-lowercase"
                        @click="onSort(tblHeader.column)"
                      >
                        <template v-if="sort.column == tblHeader.column">
                          <v-icon v-if="sort.mode == 'ASC'">mdi-arrow-up</v-icon>
                          <v-icon
                            v-else
                            :key="`tblHeader-sort-desc`"
                          >mdi-arrow-down</v-icon>
                        </template>

                        {{ $t(tblHeader.label) }}
                      </div>
                    </template>
                    <template v-else>
                      {{ $t(tblHeader.label) }}
                    </template>
                  </th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <slot name="tbody">
                <tr
                  v-for="(item, index) in trList"
                  :key="`item-tr-${index}`"
                >
                  <td>
                    <v-checkbox
                      v-model="selected"
                      :value="item.id"
                    ></v-checkbox>
                  </td>
                  <td
                    v-for="(fillable, bodyIndex) in headers"
                    :key="`item-body-${bodyIndex}`"
                  >
                    <!-- {{getValueByColunm(fillable.column, item)}} -->
                    <template v-if="fillable.baseTool">
                      <v-btn
                        icon
                        color="primary"
                        @click="onItemClick(index)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="pink"
                        @click="onItemDelete(index, true)"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="fillable.image || fillable.avatar">
                      <template v-if="fillable.image">
                        <v-img
                          v-if="getValueByColunm(fillable.column, item)"
                          width="125"
                          :src="getValueByColunm(fillable.column, item)"
                        ></v-img>
                        <v-img
                          v-else
                          :key="`item-body-img-${index}`"
                          width="125"
                          src="https://cdn.appedr.com/img/no_picture_thumb.jpg"
                        ></v-img>
                      </template>
                      <template v-else-if="fillable.avatar">
                        <v-avatar>
                          <img
                            :src="getValueByColunm(fillable.column, item)"
                            :alt="item.id"
                          />
                        </v-avatar>
                      </template>
                    </template>
                    <template v-else-if="fillable.date || fillable.datetime">
                      <template v-if="fillable.datetime">
                        {{
                        getValueByColunm(fillable.column, item) | moment("YYYY/MM/DD, HH:mm")
                      }}
                      </template>
                      <template v-else>
                        {{
                        getValueByColunm(fillable.column, item) | moment("YYYY/MM/DD")
                      }}
                      </template>

                    </template>
                    <template v-else-if="fillable.status">
                      <v-icon :color="
                          getValueBoolean(fillable.column, item)
                            ? 'green accent-4'
                            : 'grey'
                        ">mdi-check-circle</v-icon>
                    </template>
                    <template v-else-if="fillable.linkable != null">
                      <a
                        v-if="fillable.linkable.external"
                        :href="item[fillable.column]"
                        target="_blank"
                      >{{ getValueByColunm(fillable.column, item) }}</a>
                      <v-btn
                        link
                        v-else
                        :key="`item-body-link-${bodyIndex}`"
                        text
                        small
                        :to="getValueByColunm(fillable.column, item)"
                      >{{ getValueByColunm(fillable.column, item) }}</v-btn>
                    </template>
                    <template v-else>
                      {{
                        fillable.column
                          ? getValueByColunm(fillable.column, item)
                            ? getValueByColunm(fillable.column, item)
                            : ""
                          : ""
                      }}
                    </template>
                  </td>
                </tr>
              </slot>
            </tbody>
          </template>
        </v-simple-table>
        <wee-error
          v-if="trList.length == 0"
          :image="true"
        />
      </v-card-text>
    </v-card>
    <!-- Paging -->
    <slot name="tpaging">
      <v-row
        v-if="pages"
        justify="start"
        align="center"
      >
        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            :items="pages.perPageList"
            v-model="pages.itemsPerPage"
            :label="$t('paging.rowsPerPage')"
          ></v-select>
        </v-col>

        <v-col
          v-if="pages.itemsPerPage > 0"
          cols="12"
          sm="10"
        >
          <v-pagination
            v-model="pages.current"
            :length="pages.totalPage"
            :total-visible="7"
            :circle="false"
          ></v-pagination>
        </v-col>
      </v-row>
    </slot>
    <!-- End Paging  -->
  </div>
</template>

<script>
/*

*/
import { getValFromObjectByPath } from "@/plugins/util";
import {
  defineComponent,
  ref,
  watch,
  reactive,
  computed
} from "@vue/composition-api";
export default defineComponent({
  components: {
    WeeError: () => import("@/components/WeeError")
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    pages: {
      type: Object,
      default: null
    },
    headers: {
      type: Array,
      default: []
    },
    trList: {
      type: Array,
      default: []
    },
    sort: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit, root }) {
    const { $i18n } = root;
    const searchText = ref("");
    const selectedAll = ref(false);
    const selected = ref([]);
    const showAdvanceSearch = ref(false);
    const advanceSearchs = ref([]);

    const actions = reactive([
      {
        text: $i18n.tc("base.addNew"),
        value: 1,
        icon: "mdi-plus",
        color: "primary"
      },
      {
        text: $i18n.tc("base.deleteSeleted"),
        value: 2,
        icon: "mdi-delete",
        color: "error"
      },
      {
        text: $i18n.tc("base.reload"),
        value: 3,
        icon: "mdi-reload",
        color: "info"
      },
      {
        text: $i18n.tc("base.searchAdvance"),
        value: 4,
        icon: "mdi-magnify",
        color: ""
      }
    ]);

    const onMenuClick = menu => {
      if (menu === 1) {
        emit("on-open-new-form");
      } else if (menu === 2) {
        if (selected.value.length == 0) {
          return;
        }
        // const ids = selected.value;
        // console.log(ids.join("_"));
        onItemDelete(selected.value, false);
        selected.value = [];
      } else if (menu === 3) {
        emit("on-reload-page");
      } else if (menu === 4) {
        showAdvanceSearch.value = !showAdvanceSearch.value;
      }
    };
    const onItemClick = index => {
      emit("on-item-click", index);
    };
    const onItemDelete = (index, isSingle) => {
      emit("on-item-delete", index, isSingle);
    };
    const getValueByColunm = (column, item) => {
      if (!column || !item) {
        return null;
      }
      const path = column.split(".");
      if (path.length > 1) {
        return getValFromObjectByPath(item, column);
      }
      return item[column] ? item[column] : null;
    };
    const getValueBoolean = (column, item) => {
      if (!column || !item) {
        return null;
      }
      const path = column.split(".");
      let val = false;
      if (path.length > 1) {
        val = getValFromObjectByPath(item, column);
      }
      val = item[column] ? item[column] : null;
      return val === 1 || val === "1" || val === true;
    };
    const onSort = column => {
      if (props.sort.column === column) {
        props.sort.mode = props.sort.mode === "ASC" ? "DESC" : "ASC";
      } else {
        props.sort.column = column;
        // props.sort.mode = "ASC";
      }
      // emit("onSort", column);
    };

    //advance search
    const searchableHeaders = computed(() => {
      const list = props.headers;
      return list.filter(c => c.searchable === true);
    });
    const manageAdvanceSearch = (event, key) => {
      if (!event) {
        return;
      }
      console.log("manageAdvanceSearch", event, key);

      let findExist = advanceSearchs.value.find(x => x.key === key);
      if (!findExist) {
        advanceSearchs.value.push({
          key: key,
          value: event
        });
      } else {
        findExist.value = event;
      }
    };
    const resetSearch = () => {
      advanceSearchs.value = [];
      document.getElementById("wee-tbl-seacherable-form").reset();
    };

    watch(selectedAll, val => {
      selected.value = [];
      if (val) {
        for (let i = 0; i < props.trList.length; i++) {
          const item = props.trList[i];
          if (item) {
            selected.value.push(item.id);
          }
        }
      }
    });

    return {
      actions,
      selectedAll,
      selected,
      searchText,
      onItemClick,
      onItemDelete,
      getValueByColunm,
      getValueBoolean,
      onSort,
      onMenuClick,
      //advance search
      showAdvanceSearch,
      advanceSearchs,
      searchableHeaders,
      manageAdvanceSearch,
      resetSearch
    };
  }
});
</script>

<style></style>
